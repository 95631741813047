<template>
  <section id="content-home">
      <AppCard v-for="article in data.articles" 
      :key="article.slug"
      :article="article"
      />
      <b-pagination
      v-if="totalPages > 10"
      v-model="modelCurrentPage"
      :total-rows="totalPages"
      :per-page="perPage"/>
      <div v-if="data.articlesCount === 0 && isLoading === false" class="mt-4">No articles are here... yet.</div>
  </section>
</template>

<script>
import AppCard from '../Card.vue'

export default {
  components: {
    AppCard
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      currentPage: 1,
      perPage: 10
    }
  },
  computed: {
    modelCurrentPage: {
      get () {
        return this.currentPage
      },
      set (value) {
        this.$emit('changePage', value)
        this.currentPage = value
      }
    },
    totalPages () {
      return this.data.articlesCount
    }
  }
}
</script>

<style lang="scss">
  #content-home {
    // > .card:last-of-type {
    //     background: red;
    // }
 }
</style>