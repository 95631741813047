<template>
  <b-container>
    <b-row class="container-content mt-4">
      <b-col cols=9>
        <b-nav tabs>
          <b-nav-item 
          v-if="userHasLogged"
          :active="activeNavItem === 'userFeed'"
          @click="setUserFeed()">
            Your feed
          </b-nav-item>
          <b-nav-item 
          :active="activeNavItem === 'globalFeed'"
          @click="setGlobalFeed()">
            Global feed
          </b-nav-item>
          <b-nav-item 
          v-if="tagFeed !== undefined"
          :active="activeNavItem === 'tagFeed'"
          class="text-uppercase"
          @click="setTagFeed(tagFeed)">
            #{{ tagFeed }}
          </b-nav-item>
        </b-nav>
        <AppHomeContent 
        @changePage="changePage" 
        :isLoading="isLoading"
        :data="bodyRequestArticles"/>
        <b-spinner v-if="isLoading" label="Loading..." class="my-4"/>
      </b-col>
      <b-col cols=3 tag="aside" class="container-content__tags">
        <div class="p-2 text-left">
          <h6 class="text-left mb-2">Popular Tags</h6>
          <b-button v-for="(item, index) in tags"
          :key="item + index"
          @click="setTagFeed(item)"
          class="m-1 p-1">
            {{ item }}
          </b-button>
          <div class="text-center my-4">
            <b-spinner v-if="isLoadingTags" label="Loading..."/>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import AppHomeContent from '@/components/home/Content.vue'

export default {
  name: 'Home',
  metaInfo () {
    return {
      title: `Home | test-almocogratis`
    }
  },
  components: {
    AppHomeContent
  },
  beforeMount () {
    if (this.userHasLogged) this.activeNavItem = 'userFeed'
    else this.activeNavItem = 'globalFeed'
    this.isLoadingTags = true
    this.$http.tags().then( ({ data }) => {
      this.isLoadingTags = false
      this.tags = data.tags
    })
    this.changePage()
  },
  data () {
    return {
      activeNavItem: 'userFeed',
      tagFeed: undefined,
      isLoading: false,
      isLoadingTags: false,

      bodyRequestArticles: {
        articles: [],
        articlesCount: 0
      },
      tags: [],

      limit: 10,
      offset: 0
    }
  },
  methods: {
    changePage (page = 1) {
      this.offset = (page * this.limit) - this.limit
      const type = this.activeNavItem
      const execFunction = {
        'userFeed': this.setUserFeed,
        'globalFeed': this.setGlobalFeed,
        'tagFeed': this.setTagFeed
      }
      if (this.tagFeed) {
        execFunction[type](this.tagFeed)
      } else {
        execFunction[type]()
      }
    },
    setUserFeed () {
      this.activeNavItem = 'userFeed'
      this.feedUser()
    },
    setGlobalFeed () {
      this.activeNavItem = 'globalFeed'
      this.getArticles()
    },
    setTagFeed (tag) {
      this.tagFeed = tag
      this.activeNavItem = 'tagFeed'
      this.getArticles(tag)
    },
    async getArticles ( tag ) {
      this.isLoading = true
      this.bodyRequestArticles = {
        articles: [],
        articlesCount: 0
      }
      await this.$http.articles({
          tag,
          offset: this.offset,
          limit: this.limit
        }).then( ({ data }) => {
          this.bodyRequestArticles = data 
        }).finally( () => {
          this.isLoading = false
        })
    },
    async feedUser () {
      this.isLoading = true
      this.bodyRequestArticles = {
        articles: [],
        articlesCount: 0
      }
      await this.$http.feedUser({
          offset: this.offset,
          limit: this.limit
        }).then( ({ data }) => {
          this.bodyRequestArticles = data
        }).finally( () => {
          this.isLoading = false
        })
    }
  }
}
</script>
<style lang="scss">
.container-content {
  &__tags {
    > :first-child {
      border-radius: 10px;
      background-color: lighten($dark, 90%);
      button {
        font-size: .8rem;
      }
    }
  }
}
</style>
